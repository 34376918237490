<template>
    <div class="action-premium-start">
        <template v-if="content.extend"
            ><!--연장하기-->
            <div class="message">
                <div class="message-top" v-html="$translate('ACTION_PREMIUM_EXTEND_TOP')" />
                <div class="message-mid" v-html="$translate('ACTION_PREMIUM_EXTEND_MID')" />
            </div>
        </template>
        <template v-else
            ><!--새로신청-->
            <div class="profile-container">
                <img class="photo flex-wrap" :src="content.photo_url" @error="$event.target.src = $blankProfile" />
                <div class="profile">
                    <img class="premium-img" src="@/assets/images/premium.png" />
                    <div class="name" v-html="`주선자 ${(content || {}).name}`" />
                </div>
            </div>
            <div class="hr" />
            <div class="message">
                <div class="message-top" v-html="$translate('ACTION_PREMIUM_START_TOP')" />
                <!-- <div class="message-mid" v-html="$translate('ACTION_PREMIUM_START_MID')" /> -->
                <!-- <div
                    class="message-bottom"
                    v-html="
                        $translate('ACTION_PREMIUM_START_BOTTOM')
                            .replace('%s', this.businessHours().startTime)
                            .replace('%s', this.businessHours().endTime)
                            .replace('%s', this.businessHours().endTime)
                    "
                /> -->
            </div>
        </template>
    </div>
</template>

<script>
export default {
    name: 'ActionPremiumStart',
    props: ['message'],
    computed: {
        chat() {
            return this.$store.getters.chat
        },
        content() {
            return this.$mustParse(this.message.content) || {}
        },
        user() {
            return this.content.user || {}
        },
        profile() {
            return this.chat.user.profile
        },
        agentId() {
            return this.chat.agent_id
        },
    },
    methods: {
        businessHours() {
            // const agent10to19 = [17531, 17532, 17533, 5257]
            const agent13to22 = [17696, 17697, 17698]
            // 프리미엄 주선자 업무시간 관련 처리인데 디비에 칼럼추가하기그래서 주선자 아이디랑 업무 시간을 하드코딩으로 박아놓음
            // 나중에 프로퍼티나 어드민 추가해서 처리하거나..
            if (this.agentId && agent13to22.find(i => i === this.agentId)) {
                return { startTime: '오후 1', endTime: '오후 10' }
            } else {
                return { startTime: '오전 10', endTime: '오후 7' }
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.action-premium-start {
    .profile-container,
    .question,
    .button-container {
        padding: 0 12px;
    }

    .hr {
        width: 100%;
        height: 1px;
        background: $grey-02;
        margin-top: 8px;
        margin-bottom: 12px;
    }

    .profile-container {
        display: flex;

        .photo {
            width: 44px;
            height: 44px;
            border-radius: 40%;
            border: solid 1px $grey-02;
            margin-right: 12px;
        }

        .profile {
            width: 100%;
            align-self: center;

            .premium-img {
                width: 66px;
                height: 20px;
            }

            .name {
                font-size: 14px;
                height: 20px;
                color: black;

                @include f-medium;
            }

            .sub {
                display: flex;
                font-size: 12px;
                width: 100%;

                .job,
                .location {
                    height: 18px;
                    color: $grey-07;
                }
                .bar {
                    color: $grey-04;
                    margin-left: 4px;
                    margin-right: 4px;
                }
            }
        }

        .profile-btn {
            width: 36px;
            min-width: 36px;
            height: 36px;
            border-radius: 8px;
            background-color: $grey-02;
            display: flex;
            object-fit: contain;
            justify-content: center;
            align-items: center;
        }
    }

    .message {
        margin-left: 12px;

        .message-top {
            font-size: 16px;
            font-family: NotoSans-Medium;
            line-height: 1.33;
            text-align: left;
            color: black;
            margin-bottom: 12px;
        }
        .message-mid {
            font-size: 12px;
            line-height: 1.33;
            text-align: left;
            color: $grey-09;
            margin-bottom: 12px;
        }
        .message-bottom {
            font-size: 12px;
            line-height: 1.33;
            text-align: left;
            color: #262289;
            margin-bottom: 12px;
        }
    }
}
</style>
